<script>
import Base from '@backend/Base.vue';
import draggable from 'vuedraggable'
import BOGen from '@helper/BOGen';
export default {
    extends: Base,
    components: {
            draggable,
        },
    data() {
        return {
            Name: "ProjectProducts",
            row: {},
            project:{},
            unit:{},
            input:{},
            data:[],
            gl:{},
            gallery:[],
        }
    },
    computed: {
        
    },
    mounted() {
        this.$set(this.$root, 'page', this)
        this.refreshData()
        this.site=this.row
    },
    methods: { 
        submitForm(e, callback){
          if(e&&e.btnLoading()) return;
          this.row.type="editStatict"
          BOGen.apirest("/"+this.Name, this.row, (err, resp)=>{
            if(e) e.btnUnloading()
            if(err){
              if(err.status == 422){
                Object.keys(err.responseJSON).forEach((k)=>{
                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
                })
              }
              return
            }
            if(resp.error){
              if(this.errorFormCallback) return this.errorFormCallback(resp);
              if(callback) return callback(resp);
              return Gen.info(resp.message, "danger")
            }
            if(resp.success){
              if(this.successFormCallback) return this.successFormCallback(resp);
              if(callback) return callback(resp);
              setTimeout(()=>{
                // $('[href="#collapseExample"]').click();
                this.refreshData()
              },2000)
              return Gen.info(resp.message, "success", 1000).then(()=>{
                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
              })
            }
          },"POST")
        }, 
        addUnit(){
            this.input ={}
            this.input.mpu_is_active='Y'
            this.input.type='addUnit'
            this.input.spec= [{spc_img:'',spc_title:''}]
            $("#collapseExample").addClass('show');
        },
        editUnit(v){
            console.log(v)
            this.input=v
            this.input.type='editUnit'
            $("#collapseExample").addClass('show');
        },
        delUnit(id){
          var v = id
          swal({
            title: "Are you sure to delete "+this.ObjectName+"?",
            text:"",
            icon: "warning",
            buttons: ["No, Cancel!", "Yes, Sure!"],
            dangerMode: true,
          }).then((ok) => {
            if (ok) {
              BOGen.apirest("/"+this.Name, {type:"deleteUnit",id:v},()=>{
                swal("Deleted!", "Information has been deleted.", "success");
                this.refreshData()
              }, "POST")
            }
          });
        },        
        submitUnit(e, callback){
          if(e&&e.btnLoading()) return;
          this.input.mpu_project = App.$route.params.id
          BOGen.apirest("/"+this.Name, this.input, (err, resp)=>{
            if(e) e.btnUnloading()
            if(err){
              if(err.status == 422){
                Object.keys(err.responseJSON).forEach((k)=>{
                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
                })
              }
              return
            }
            if(resp.error){
              if(this.errorFormCallback) return this.errorFormCallback(resp);
              if(callback) return callback(resp);
              return Gen.info(resp.message, "danger",1000,'.info-unit')
            }
            if(resp.success){
              if(this.successFormCallback) return this.successFormCallback(resp);
              if(callback) return callback(resp);
              setTimeout(()=>{
                $('#collapseExample').addClass('show');
                this.refreshData()
              },2000)
              return Gen.info(resp.message, "success", 1000,'.info-unit').then(()=>{
                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
              })
            }
          },"POST")
        },     
        endDrag() {
            BOGen.apirest('/' + this.Name, {
                data: this.data,
                type: 'sortUnit'
            }, (err, resp) => {
                console.log(resp)
            }, "POST");
        },        
        addSpec(){
            this.input.spec.push({spc_img:'',spc_title:''})
        },
        delSpec(k){
            this.input.spec.splice(k,1)
        }, 
        addGallery(){
            this.gl = {}
            this.gl.mpg_is_active='Y'
            this.gl.type='addGallery'
            $('#collapseExampleGallery').addClass('show');
        },
        editGallery(v){
            console.log(v)
            this.gl=v
            this.gl.type='editGallery'
            $('#collapseExampleGallery').addClass('show');
        },
        delGallery(id){
          var v = id
          swal({
            title: "Are you sure to delete image?",
            text:"",
            icon: "warning",
            buttons: ["No, Cancel!", "Yes, Sure!"],
            dangerMode: true,
          }).then((ok) => {
            if (ok) {
              BOGen.apirest("/"+this.Name, {type:"deleteUnit",id:v},()=>{
                swal("Deleted!", "Information has been deleted.", "success");
                this.refreshData()
              }, "POST")
            }
          });
        },        
        submitGallery(e, callback){
          if(e&&e.btnLoading()) return;
          this.gl.mpg_project = App.$route.params.id
          BOGen.apirest("/"+this.Name, this.gl, (err, resp)=>{
            if(e) e.btnUnloading()
            if(err){
              if(err.status == 422){
                Object.keys(err.responseJSON).forEach((k)=>{
                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
                })
              }
              return
            }
            if(resp.error){
              if(this.errorFormCallback) return this.errorFormCallback(resp);
              if(callback) return callback(resp);
              return Gen.info(resp.message, "danger",1000,'.info-unit')
            }
            if(resp.success){
              if(this.successFormCallback) return this.successFormCallback(resp);
              if(callback) return callback(resp);
              setTimeout(()=>{
                $('#collapseExampleGallery').addClass('show');
                this.refreshData()
              },2000)
              return Gen.info(resp.message, "success", 1000,'.info-unit').then(()=>{
                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
              })
            }
          },"POST")
        },     
        endDragGallery() {
            BOGen.apirest('/' + this.Name, {
                data: this.data,
                type: 'sortGallery'
            }, (err, resp) => {
                console.log(resp)
            }, "POST");
        }, 
    },
    watch:{
    }

};
</script>
<template>
<div class="container-fluid">
    <PageTitle></PageTitle>
   <div class="topnavBackoffice">
        <router-link :to="{name:'ProjectHome',params:{id:$route.params.id}}">Beranda</router-link>
        <router-link class="active" :to="{name:'ProjectProducts',params:{id:$route.params.id}}">Produk</router-link>
        <router-link :to="{name:'ProjectAbout',params:{id:$route.params.id}}">Tentang Kami</router-link>
        <a href="javascript:;" class="dropdown_bo">Artikel & Progres
          <ul class="dropdown_menu">
            <li><router-link :to="{name:'ProjectNews',params:{id:$route.params.id}}">Artikel</router-link></li>
            <li><router-link :to="{name:'ProjectProgress',params:{id:$route.params.id}}">Perkembangan Proyek</router-link></li>
          </ul>
        </a>
        <router-link :to="{name:'ProjectContact',params:{id:$route.params.id}}">Lokasi & Kontak</router-link>
    </div>

    <div class="row">
        <!-- Start Contact -->
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Header Section</h5>
                    <VForm @resp="submitForm" method="post">
                    <div class="row">
                        <div class="col-md-12 info"></div>
                        <div class="col-md-6">
                            <BoField name="sc_title" v-model="row.sc_title" mandatory></BoField>
                            
                        </div>
                        <div class="col-md-12">
                            <BoField name="sc_desc" mandatory>
                                <CKEditor name="content" class="form-control" v-model="row.sc_desc"></CKEditor>
                            </BoField>
                        </div>
                        <div class="col-md-12 text-right"><button type="submit" class="btn btn-rounded btn-info btn-loading">Update Header</button></div>
                    </div>
                    </VForm>
                </div>
            </div>
        </div>
        <!-- End Contact -->

        <!-- Show Specification -->
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Tipe Unit {{project.mp_title}}</h5>
                    <draggable v-model="data" class="row" @end="endDrag">

                        <div style="cursor:move;" class="col-md-3" :id="'dat'+v.mpu_id" v-for="(v,k) in data" :key="k">
                            <div class="wrap_slider_img">
                                <img :src="uploader(v.mpu_thumb_img)" class="img-responsive" />
                                <div class="slider_name">
                                    <p>{{v.mpu_jenis}}</p>
                                </div>
                                <a data-toggle="collapse" @click="editUnit(v)" href="javascript:;"
                                    class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                                <a href="javascript:;" @click="delUnit(v.mpu_id)" class="bullet_delete"><i
                                        class="far fa-trash-alt"></i></a>
                                <span class="label label-success" v-if="v.mpu_is_active == 'Y'">Active</span>
                                <span class="label label-danger" v-else>Inactive</span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <a class="wrap_upload" data-toggle="collapse" @click="addUnit" href="javascript:;"
                                role="button" aria-expanded="false" aria-controls="collapseExample">
                                <div class="ic_wrap">
                                    <i class="fas fa-plus"></i>
                                    <p>New Unit</p>
                                </div>
                            </a>
                        </div>
                    </draggable>
                    <VForm @resp="submitUnit" method="post">
                        <div class="row collapse mt-4" id="collapseExample">
                            <div class="col-12 info-unit"></div>
                            <div class="col-md-4 mb-3">
                                <BoField name="mpu_thumb_img" mandatory>
                                    <Uploader :param="{thumbnail:true}" name="mpu_thumb_img"
                                        v-model="input.mpu_thumb_img" type="thumbunit" uploadType="cropping"></Uploader>
                                </BoField>
                                <BoField name="mpu_thumb_img_alt" v-model="input.mpu_thumb_img_alt"></BoField>
                                <BoField name="mpu_img" mandatory>
                                    <Uploader :param="{thumbnail:true}" name="mpu_img" v-model="input.mpu_img"
                                        type="unit" uploadType="cropping"></Uploader>
                                </BoField>
                                <BoField name="mpu_img_alt" v-model="input.mpu_img_alt"></BoField>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="panel_group">
                                            <div class="panel_heading">Content</div>
                                            <div class="panel_body">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <BoField name="mpu_jenis" v-model="input.mpu_jenis" required></BoField>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <BoField name="mpu_tipe" :label="'Type Size'">
                                                            <TagsInput name="mpu_tipe" :placeholder="'Ex: 36/40'"
                                                                style="display:block" v-model="input.mpu_tipe"
                                                                :attr="validation('mpu_tipe')"></TagsInput>
                                                        </BoField>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <BoField name="mpu_desc">
                                                            <textarea v-model="input.mpu_desc" rows="4"
                                                                class="form-control"></textarea>
                                                        </BoField>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <BoField name="mpu_is_active">
                                                            <div class="row">
                                                                <radio name="mpu_is_active"
                                                                    v-model="input.mpu_is_active" option="Y"
                                                                    :attr="validation('mpu_is_active')">Active</radio>
                                                                <radio name="mpu_is_active"
                                                                    v-model="input.mpu_is_active" option="N">Inactive
                                                                </radio>
                                                            </div>
                                                        </BoField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="panel_group">
                                            <div class="panel_heading">Specification</div>
                                            <div class="panel_body">
                                                <div class="row">
                                                    <div class="col-md-3" v-for="(v,k) in input.spec" :key="k">
                                                        <BoField name="spc_img">
                                                            <Uploader :param="{thumbnail:true}" name="spc_img"
                                                                v-model="v.spc_img" type="adventage"
                                                                uploadType="cropping"></Uploader>
                                                        </BoField>
                                                        <BoField name="spc_title" v-model="v.spc_title" required>
                                                        </BoField>
                                                        <a v-if="input.spec.length>1" href="javascript:;"
                                                            @click="delSpec(k)" class="btn btn-danger btn-block mb-3">Delete
                                                            Specification</a>
                                                    </div>
                                                    <div class="col-md-12 text-right">
                                                        <a href="javascript:;" class="btn btn-success"
                                                            @click="addSpec">Add Specification</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3 text-right">
                                        <button type="submit"
                                            class="btn btn-rounded btn-info btn-loading">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VForm>
                </div>
            </div>
        </div>
        <!-- End Show Specification -->
        <!-- Site Plan -->
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <div class="col-12 info-site"></div>
                    <h5 class="card-title">Site Plan</h5>
                    <VForm @resp="submitForm" method="post">
                    <div class="row">
                        <div class="col-md-12 info"></div>
                        <div class="col-md-6">
                            <BoField name="sc_background" :label="'Image Site Plan'">
                                <Uploader :param="{thumbnail:true}" name="sc_background" v-model="row.sc_background" type="siteplan" uploadType="cropping"></Uploader>
                            </BoField>
                            <BoField :label="'Image Alt'" name="sc_content" v-model="row.sc_content"></BoField>
                        </div>
                        <div class="col-md-12 text-right"><button type="submit" class="btn btn-rounded btn-info btn-loading">Update Site Plan</button></div>
                    </div>
                    </VForm>
                </div>
            </div>
        </div>
        <!-- Site Plan -->

        <!-- Show Specification -->
        <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
            <div class="card">
                <div class="card-body">
                    <h5 class="card-title">Gallery {{project.mp_title}}</h5>
                    <draggable v-model="gallery" class="row" @end="endDragGallery">

                        <div style="cursor:move;" class="col-md-3" :id="'dat'+vg.mpg_id" v-for="(vg,kg) in gallery"
                            :key="kg">
                            <div class="wrap_slider_img">
                                <img :src="uploader(vg.mpg_img)" class="img-responsive" />
                                <div class="slider_name">
                                    <p>{{vg.mpg_title}}</p>
                                </div>
                                <a data-toggle="collapse" @click="editGallery(vg)" href="javascript:;"
                                    class="bullet_edit"><i class="fas fa-pencil-alt"></i></a>
                                <a href="javascript:;" @click="delGallery(vg.mpg_id)" class="bullet_delete"><i
                                        class="far fa-trash-alt"></i></a>
                                <span class="label label-success" v-if="vg.mpg_is_active == 'Y'">Active</span>
                                <span class="label label-danger" v-else>Inactive</span>
                            </div>
                        </div>

                        <div class="col-md-3">
                            <a class="wrap_upload" data-toggle="collapse" @click="addGallery"
                                href="javascript:;" role="button" aria-expanded="false"
                                aria-controls="collapseExampleGallery">
                                <div class="ic_wrap">
                                    <i class="fas fa-plus"></i>
                                    <p>New Image</p>
                                </div>
                            </a>
                        </div>
                    </draggable>
                    <VForm @resp="submitGallery" method="post">
                        <div class="row collapse mt-4" id="collapseExampleGallery">
                            <div class="col-12 info-unit"></div>
                            <div class="col-md-4 mb-3">
                                <BoField name="mpg_img" mandatory>
                                    <Uploader :param="{thumbnail:true}" name="mpg_img" v-model="gl.mpg_img"
                                        type="gallery" uploadType="cropping"></Uploader>
                                </BoField>
                            </div>
                            <div class="col-md-8 mb-3">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="panel_group">
                                            <div class="panel_heading">Content</div>
                                            <div class="panel_body">
                                                <div class="row">
                                                    <div class="col-md-9">
                                                        <BoField name="mpg_title" v-model="gl.mpg_title" required></BoField>
                                                        <BoField name="mpg_img_alt" v-model="gl.mpg_img_alt"></BoField>
                                                    </div>
                                                    <div class="col-md-9">
                                                        <BoField name="mpg_is_active">
                                                            <div class="row">
                                                                <radio name="mpg_is_active" v-model="gl.mpg_is_active"
                                                                    option="Y" :attr="validation('mpg_is_active')">
                                                                    Active</radio>
                                                                <radio name="mpg_is_active" v-model="gl.mpg_is_active"
                                                                    option="N">Inactive</radio>
                                                            </div>
                                                        </BoField>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 mt-3 text-right">
                                        <button type="submit"
                                            class="btn btn-rounded btn-info btn-loading">Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VForm>
                </div>
            </div>
        </div>
        <!-- End Show Specification -->
        </div>


</div>
</template>